<template>
<body>
   
</body>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            
            pageData: [],
            url: 'http://127.0.0.1:8000/',
            fields: {
                type: 'update_profile',
                uid: '',
                fullname: '',
                mobile: '',
                address: '',
                Token: this.$root.auth
            }
        }
    },
    mounted() {
        this.uid = localStorage.getItem('user')
        this.pData()
        this.goToTop()
    },
    methods: {
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        pData() {
            const axios = require('axios')
            var self = this
            axios.post(this.url + 'api?type=Dilip', {
                type:'aboutus'
            }).then(function (response) {
                var data = response.data
                self.pageData = data.data
                console.log(response)
            })
        }
    }
}
</script>
